import { DeviseTokenAuthAuthHeaders } from "@/store/modules/AuthModule";

const AUTH_HEADERS_KEY = "authHeaders" as string;

export const authHeadersValid = (authHeaders: DeviseTokenAuthAuthHeaders | null): boolean => {
  if (!authHeaders) {
    authHeaders = getAuthHeaders();
    if (!authHeaders) return false;
  }

  if (typeof authHeaders !== "object") return false;
  if (Object.keys(authHeaders).indexOf("access-token") === -1) return false;
  // noinspection RedundantIfStatementJS
  if (!authHeaders["access-token"]) return false;

  return true;
};


/**
 * @description get token form localStorage
 */
export const getAuthHeaders = (): DeviseTokenAuthAuthHeaders | null => {
  const value = window.localStorage.getItem(AUTH_HEADERS_KEY);

  return value ? JSON.parse(value) : null;
};

/**
 * @description save authHeaders into localStorage
 * @param authHeaders: string
 */
export const saveAuthHeaders = (authHeaders: DeviseTokenAuthAuthHeaders): void => {
  window.localStorage.setItem(AUTH_HEADERS_KEY, JSON.stringify(authHeaders));
};

/**
 * @description remove token form localStorage
 */
export const voidAuthHeaders = (): void => {
  window.localStorage.removeItem(AUTH_HEADERS_KEY);
};

export default { getAuthHeaders, saveAuthHeaders, voidAuthHeaders, authHeadersValid };
