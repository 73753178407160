/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { IDwelling, IDwellingSearchResponseRow } from "@/interfaces/IDwelling";
import { IChange, IGuestsCount } from "@/interfaces/common";

export interface IReorderDwellingPhoto {
  id: number | string,
  order_num: number
}

export async function dwellingsGetPendingIds(): Promise<IDwelling[]> {
  const response = await axios.get("/dwellings/?pending");
  return response.data.data;
}

export async function getFirstDwelling(): Promise<any> {
  const response = await axios.get(`/dwellings/first`);
  return response.data;
}

export async function dwellingsGet(dwellingId): Promise<any> {
  const response = await axios.get(`/dwellings/${dwellingId}`);
  return response.data.data;
}

export async function getUserDwellings(userId: string): Promise<IDwelling[]> {
  const response = await axios.get(`/users/${userId}/dwellings`);
  return response.data.data;
}

export async function dwellingsCreate(): Promise<string> {
  const response = await axios.post("/dwellings");
  return response.data;
}

export async function updateDwelling(dwellingId: string, params: any, force?: boolean, config?: { [p: string]: any }): Promise<any> {
  force = !!force;
  config = config || {};

  const response = await axios.patch(`/dwellings/${dwellingId}`, { dwelling: params, force: force }, config);
  return response.data.data;
}

export async function removeDwelling(dwellingId: string): Promise<void> {
  await axios.delete(`/dwellings/${dwellingId}`);
}

export async function setDwellingAddress(dwellingId: string, address: any, force?: boolean, config?: { [p: string]: any }): Promise<IDwelling> {
  config = config || {};

  const response = await axios.patch(`/dwellings/${dwellingId}/set_address`, { address, force }, config);
  return response.data.data;
}

export async function setDwellingAmenities(dwellingId: string, amenitiesIds: string[]): Promise<void> {
  await axios.patch(`/dwellings/${dwellingId}/set_amenities`, { amenity_ids: amenitiesIds });
}

export async function setDwellingScenicViews(dwellingId: string, scenicViewIds: string[]): Promise<void> {
  await axios.patch(`/dwellings/${dwellingId}/set_scenic_views`, { scenic_view_ids: scenicViewIds });
}

export async function cloneDwelling(dwellingId: string): Promise<IDwelling> {
  const response = await axios.post(`/dwellings/${dwellingId}/clone`);
  return response.data.data;
}

export async function updateDwellingPhoto(dwellingId, dwellingPhotoId, data): Promise<void> {
  await axios.patch(`/dwellings/${dwellingId}/photos/${dwellingPhotoId}`, data);
}

export async function removeDwellingPhoto(dwellingId, dwellingPhotoId): Promise<void> {
  await axios.delete(`/dwellings/${dwellingId}/photos/${dwellingPhotoId}`);
}

export async function getDwellingPhotos(dwellingId): Promise<any> {
  const response = await axios.get(`/dwellings/${dwellingId}/photos`);
  return response.data.data;
}

export async function reorderDwellingPhotos(dwellingId, reorderData: IReorderDwellingPhoto[]): Promise<any> {
  const response = await axios.post(`/dwellings/${dwellingId}/photos/reorder`, { new_order: reorderData });
  return response.data;
}

export async function searchDwellings(searchParams): Promise<IDwellingSearchResponseRow[]> {
  const response = await axios.get(`/dwellings/search`, { params: searchParams });
  return response.data;
}

export interface IAlternateLocations {
  locations: string[]
  rid: string
}
export async function searchAlternativeLocation(locationName: string, currentSearchRequestId: string): Promise<IAlternateLocations> {
  const response = await axios.get(`/dwellings/search_location`, { params: { l: locationName, rid: currentSearchRequestId } });
  return response.data;
}

interface ILatLngSearchParams {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  guests_count: IGuestsCount;
  book_from: string;
  book_to: string;
}

export async function searchDwellingsByLatLng(searchParams: ILatLngSearchParams): Promise<IDwellingSearchResponseRow[]> {
  const response = await axios.get(`/dwellings/search_by_bounds`, { params: searchParams });
  return response.data.data;
}

interface ISingleDwellingIdResponse {
  dwelling_id: string;
}

export async function checkIfUserLinkAvailable(userLink: string): Promise<ISingleDwellingIdResponse> {
  const response = await axios.get(`/dwellings/check_user_link?user_link=${userLink}`);
  return response.data.data;
}

export async function getDwellingIdByUserLink(userLink: string): Promise<ISingleDwellingIdResponse> {
  const response = await axios.get(`/dwellings/user_link/${userLink}`);
  return response.data.data;
}

export async function dwellingsIndex  (): Promise<IDwelling[]> {
  const response = await axios.get(`/dwellings/`);
  return response.data.data;
}

export async function getDwellingDocuments(dwellingId): Promise<any[]> {
  const response = await axios.get(`/dwellings/${dwellingId}/documents`);
  return response.data.data;
}

export async function removeDwellingDocument(dwellingId, documentId): Promise<void> {
  await axios.delete(`/dwellings/${dwellingId}/documents/${documentId}`);
}

export async function dwellingChangesIndex(dwellingId, pageNum): Promise<IPaginatedResponse<IChange[]>> {
  const response = await axios.get(`/dwellings/${dwellingId}/changes?page=${pageNum}`);
  return response.data.data;
}

export async function dwellingsImport(payload): Promise<void> {
  await axios.post("/dwellings/import", payload);
}

