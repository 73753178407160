import WebsocketClientService from "@/core/services/WebsocketClientService";
import { Subscription } from "@rails/actioncable";
import { Store } from "vuex";

export class NotificationsService {
  public static store: Store<any>;

  public static init(store) {
    NotificationsService.store = store;
  }

  public static startListeningForNewNotifications(): Subscription | undefined {
    if (!WebsocketClientService.client) return;

    return WebsocketClientService.client.subscriptions.create({
      channel: "NotificationsChannel",
    }, {
      connected() {
        console.log("[NotificationsService] connected", this);
      },
      disconnected() {
        console.log("[NotificationsService] disconnected", this);
      },
      rejected() {
        console.log("[NotificationsService] rejected", this);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      received(message: any) {
        const store = NotificationsService.store;
        // console.log("[NotificationsService] received", obj);
        switch (message.type) {
          case "new":
            store.commit("addNotification", message.payload);
            break;
          case "add_bulk":
            store.commit("addNotifications", message.payload);
            break;
          case "read_all":
            store.commit("readAllNotifications");
            break;
          case "hide_read":
            store.commit("hideReadNotifications");
            break;
          case "update":
            store.commit("updateNotification", message.payload);
            break;
        }
      },
    }) as Subscription;
  }
}