import { wsHost } from "@/core/data/constants";
import { Consumer, createConsumer } from "@rails/actioncable";
import { App } from "vue";
import { useToast } from "vue-toastification";
import DeviseTokenService from "@/core/services/DeviseTokenService";
import qs from "qs";

const toast = useToast();

export default class WebsocketClientService {
  public static client: Consumer;
  public static vueInstance: App;

  public static init(app: App<Element>) {
    WebsocketClientService.vueInstance = app;
  }

  public static startListening() {
    WebsocketClientService.client = createConsumer(WebsocketClientService.getWsUrl);
  }

  private static getWsUrl(): string {
    const authHeaders = DeviseTokenService.getAuthHeaders();
    const authHeadersAsGetParams = qs.stringify(authHeaders);
    return `${wsHost}/ws?${authHeadersAsGetParams}`;
  }
}
