export default {
  "adult": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 взрослых"]), _normalize([_interpolate(_named("n")), " взрослый"]), _normalize([_interpolate(_named("n")), " взрослых"]), _normalize([_interpolate(_named("n")), " взрослых"])])},
  "adult_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 взрослых"]), _normalize([_interpolate(_named("n")), " взрослого"]), _normalize([_interpolate(_named("n")), " взрослых"]), _normalize([_interpolate(_named("n")), " взрослых"])])},
  "kid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 детей"]), _normalize([_interpolate(_named("n")), " ребенок"]), _normalize([_interpolate(_named("n")), " ребенка"]), _normalize([_interpolate(_named("n")), " детей"])])},
  "kid_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 детей"]), _normalize([_interpolate(_named("n")), " ребенка"]), _normalize([_interpolate(_named("n")), " ребенка"]), _normalize([_interpolate(_named("n")), " детей"])])},
  "infant": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 младенцев"]), _normalize([_interpolate(_named("n")), " младенец"]), _normalize([_interpolate(_named("n")), " младенца"]), _normalize([_interpolate(_named("n")), " младенцев"])])},
  "infant_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 младенцев"]), _normalize([_interpolate(_named("n")), " младенеца"]), _normalize([_interpolate(_named("n")), " младенцев"]), _normalize([_interpolate(_named("n")), " младенцев"])])},
  "guest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 гостей"]), _normalize([_interpolate(_named("n")), " гость"]), _normalize([_interpolate(_named("n")), " гостя"]), _normalize([_interpolate(_named("n")), " гостей"])])},
  "guest_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 гостей"]), _normalize([_interpolate(_named("n")), " гостя"]), _normalize([_interpolate(_named("n")), " гостей"]), _normalize([_interpolate(_named("n")), " гостей"])])},
  "bedrooms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 спален"]), _normalize([_interpolate(_named("n")), " спальня"]), _normalize([_interpolate(_named("n")), " спальни"]), _normalize([_interpolate(_named("n")), " спален"])])},
  "bedrooms_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 спален"]), _normalize([_interpolate(_named("n")), " спальней"]), _normalize([_interpolate(_named("n")), " спальнями"]), _normalize([_interpolate(_named("n")), " спаленями"])])},
  "beds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 кроватей"]), _normalize([_interpolate(_named("n")), " кровать"]), _normalize([_interpolate(_named("n")), " кровати"]), _normalize([_interpolate(_named("n")), " кроватей"])])},
  "bathrooms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 ванных вомнат"]), _normalize([_interpolate(_named("n")), " отдельная ванная"]), _normalize([_interpolate(_named("n")), " отдельных ванных комнаты"]), _normalize([_interpolate(_named("n")), " ванных комнат"])])},
  "animal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 животных"]), _normalize([_interpolate(_named("n")), " животное"]), _normalize([_interpolate(_named("n")), " животных"]), _normalize([_interpolate(_named("n")), " животных"])])},
  "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 отзывов"]), _normalize([_interpolate(_named("n")), " отзыв"]), _normalize([_interpolate(_named("n")), " отзыва"]), _normalize([_interpolate(_named("n")), " отзывов"])])},
  "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 секунд"]), _normalize([_interpolate(_named("n")), " секунда"]), _normalize([_interpolate(_named("n")), " секунды"]), _normalize([_interpolate(_named("n")), " секунд"])])},
  "minute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 минут"]), _normalize([_interpolate(_named("n")), " минута"]), _normalize([_interpolate(_named("n")), " минуты"]), _normalize([_interpolate(_named("n")), " минут"])])},
  "hour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 часов"]), _normalize([_interpolate(_named("n")), " час"]), _normalize([_interpolate(_named("n")), " часа"]), _normalize([_interpolate(_named("n")), " часов"])])},
  "day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 дней"]), _normalize([_interpolate(_named("n")), " день"]), _normalize([_interpolate(_named("n")), " дня"]), _normalize([_interpolate(_named("n")), " дней"])])},
  "month": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 месяцев"]), _normalize([_interpolate(_named("n")), " месяц"]), _normalize([_interpolate(_named("n")), " месяца"]), _normalize([_interpolate(_named("n")), " месяцев"])])},
  "night": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 ночей"]), _normalize([_interpolate(_named("n")), " ночь"]), _normalize([_interpolate(_named("n")), " ночи"]), _normalize([_interpolate(_named("n")), " ночей"])])},
  "new_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 новых"]), _normalize([_interpolate(_named("n")), " новое"]), _normalize([_interpolate(_named("n")), " новых"]), _normalize([_interpolate(_named("n")), " новых"])])},
  "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 выбрано"]), _normalize([_interpolate(_named("n")), " выбран"]), _normalize([_interpolate(_named("n")), " выбрано"]), _normalize([_interpolate(_named("n")), " выбрано"])])},
  "found": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Найдено"]), _normalize(["Найден"]), _normalize(["Найдено"]), _normalize(["Найдено"])])},
  "variant": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 вариантов"]), _normalize([_interpolate(_named("n")), " вариант"]), _normalize([_interpolate(_named("n")), " варианта"]), _normalize([_interpolate(_named("n")), " вариантов"])])},
  "special_offer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 специальных предложений"]), _normalize([_interpolate(_named("n")), " специальное предложение"]), _normalize([_interpolate(_named("n")), " специальных предложения"]), _normalize([_interpolate(_named("n")), " специальных предложений"])])},
  "bed_types": {
    "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Односпальная"])},
    "double": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Двухспальная"])},
    "both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть и односпальная и двухспальная"])}
  },
  "user_sections": {
    "payment_types": {
      "payment_type_0": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатите полностью."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заплатите сумму полностью и сфокусируйтесь на поездке"])},
        "short_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полная оплата через сервис Трэвелуслуги."])}
      },
      "payment_type_0_no_refund": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатите с 10% скидкой."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без отмены бронирования с предоставлением скидки на проживание."])},
        "short_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полная оплата с 10% скидкой без возврата."])}
      },
      "payment_type_10": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатите часть сейчас, а остаток внесите позже"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Внесите ", _interpolate(_named("amount")), " сейчас, а остаток (", _interpolate(_named("remaining_amount")), ") автоматически спишется с того же способа оплаты ", _interpolate(_named("auto_payment_date")), ". Без дополнительных сборов"])},
        "short_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата 50% при бронировании, 50% перед заездом."])}
      },
      "payment_type_10_no_refund": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатите с 10% скидкой."])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Без отмены бронирования с предоставлением скидки на проживание. Внесите ", _interpolate(_named("amount")), " сейчас, а остаток (", _interpolate(_named("remaining_amount")), ") автоматически спишется с того же способа оплаты ", _interpolate(_named("auto_payment_date")), ". Без дополнительных сборов."])},
        "short_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата 50% при бронировании, 40% перед заездом. Скидка 10% и без возможности отмены."])}
      },
      "payment_type_20": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатите на месте."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите данные своей карты для авторизации оплаты сбора комиссии сервиса Трэвелуслуги. Этот сбор автоматически спишется перед заездом. Остальную сумму Вы оплачиваете на месте хозяину. Для авторизации платежных данных с Вашей карты будут списаны и сразу же возвращены 11 рублей."])},
        "short_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата на месте."])}
      }
    }
  },
  "host_sections": {
    "dwelling-edit": {
      "payment_types": {
        "payment_type_0": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вся сумма сразу."])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гостю предлагаем 100% оплату через сервис Трэвелуслуги."])}
        },
        "payment_type_10": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часть суммы сразу, остаток перед заездом"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гостю предлагается 50% оплата сразу, 50% перед заездом без каких-либо доп.действий. Оплата производится через сервис Трэвелуслуги"])}
        },
        "payment_type_20": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата на месте."])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гостю предлагаем ввести банковскую карту для авторизации и валидации платежных данных без оплаты сразу. Перед заездом с карты гостя спишется 15%, остальную сумму гость оплачивает на месте хозяину"])}
        }
      }
    }
  },
  "fees": {
    "clean_up_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плата за уборку"])},
    "bedding_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбор за постельное белье"])},
    "resort_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курортный сбор"])},
    "management_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбор за управление"])},
    "additional_guest_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбор за дополнительного гостя"])},
    "animals_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбор за питомцев"])}
  },
  "notifications": {
    "new-conversation-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новое сообщение в беседе"])},
    "new-support-ticket-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новое сообщение от службы поддержки"])},
    "dwelling-status-change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменился статус объявления"])},
    "new-booking-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создано новое бронирование"])},
    "booking-record-status-change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создано новое бронирование"])},
    "booking-status-change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменился статус бронирования"])},
    "payment-method-removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удален сохраненный метод оплаты"])},
    "payment-method-added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавлен сохраненный метод оплаты"])}
  },
  "night_prices_comments": {
    "weekday_special_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спец цена определенного дня недели"])},
    "weekend_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спец цена выходного дня"])},
    "weekend_only_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тариф бронирования выходного дня"])},
    "special_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специальное предложение"])},
    "very_special_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специальное предложение"])}
  },
  "impossibleBookingErrors": {
    "cant_book_own_dwelling_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нельзя забронировать собственное жилье"])},
    "overlapping_booking_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К сожалению, выбранные даты заняты. Попробуйте использовать другие"])},
    "booking_length_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нарушено условие \"Срок проживания\""])},
    "pets_not_allowed_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проживание с животными не допускается"])},
    "unavailable_day_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["День заезда либо выезда недоступен"])},
    "guests_count_exceeded_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нарушено условие \"Колчиество гостей\""])}
  },
  "invoice_statuses": {
    "not_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не оплачен"])},
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплачен"])},
    "voided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделан возврат"])}
  },
  "discounts": {
    "grand_user_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидка лояльности для постоянных гостей"])},
    "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купон"])},
    "week_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидка за неделю"])},
    "month_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидка за месяц"])},
    "early_booking_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидка при раннем бронировании"])},
    "last_time_booking_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидки последней минуты"])}
  },
  "models": {
    "PublicProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публичный профиль"])},
    "Dwelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объявление"])},
    "DwellingsAmenity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удобство жилища"])},
    "DwellingPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фотография"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
    "DwellingScenicView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Живописный вид"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гость"])}
  },
  "fieldNames": {
    "User": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "phone_confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон подтвержден"])},
      "email_confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email подтвержден"])},
      "public_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация публичного профиля"])},
      "public_profile_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка публичного профиля"])},
      "encrypted_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
      "is_grand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус \"Гранд\""])}
    },
    "DwellingPhoto": {
      "order_num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порядок"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подпись"])}
    },
    "Address": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
      "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индекс"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Город"])},
      "street1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улица, дом"])},
      "apt_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер квартиры"])}
    },
    "BookingRecord": {
      "arrival_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время заезда"])},
      "adults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Взрослые"])},
      "kids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дети"])},
      "infants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Младенцы"])},
      "animals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Животные"])},
      "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата заезда"])},
      "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата выезда"])}
    },
    "Dwelling": {
      "alternative_location_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Альтернативное название локации"])},
      "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата последнего сохранения"])},
      "approval_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус апрува"])},
      "display_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус показа бъявления (показывается/скрыто)"])},
      "bathrooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кол-во ванных"])},
      "bedrooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кол-во спален"])},
      "beds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кол-во кроватей"])},
      "creation_finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Факт завершения создания объявления"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
      "description_amenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание удобств"])},
      "description_inner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание комнат"])},
      "description_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание местоположения"])},
      "description_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительное описание"])},
      "description_transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание транспорта"])},
      "floor_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этаж"])},
      "floors_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сколько этажей в здании"])},
      "guests_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кол-во гостей"])},
      "internal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание для админов"])},
      "internal_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутреннее название"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
      "nightly_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена за ночь"])},
      "last_status_change_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последний комментарий к изменению статуса объявления"])},
      "what_to_host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип объявления"])},
      "dwelling_type_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип жилища"])},
      "street1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улица/дом"])},
      "lat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Широта"])},
      "lon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Долгота"])},
      "dwelling_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID объявления"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания"])},
      "instant_booking_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мгновенное бронирование"])},
      "weekend_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спец цена выходного дня"])},
      "early_booking_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидка при раннем бронировании"])},
      "early_booking_discount_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кол-во месяцев для скидки раннего бронирования"])},
      "weekend_only_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тариф бронирования выходного дня"])},
      "last_time_booking_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидки последней минуты"])},
      "last_time_booking_discount_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кол-во дней для скидки последней минуты"])},
      "min_stay_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимальный срок пребывания"])},
      "min_stay_length_by_check_in_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спец условия для минимального срока пребывания"])},
      "min_stay_length_by_days_to_check_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спец условия для минимального срока пребывания"])},
      "pets_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешено ли проживание с питомцами"])},
      "animals_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбор за питомцев"])},
      "clean_up_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плата за уборку"])},
      "short_term_clean_up_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плата за уборку при коротком проживании"])},
      "restricted_check_out_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничение дней выезда"])},
      "restricted_check_in_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничение дней въезда"])},
      "max_stay_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальный срок пребывания"])},
      "instant_booking_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обязательное сообщение при мгновенном бронировании"])},
      "resort_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курортный сбор"])},
      "additional_guest_fee_apply_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кол-во гостей для применения сбора за доп. гостя"])},
      "additional_guest_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбор за дополнительного гостя"])},
      "max_kids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальное кол-во детей"])},
      "max_infants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальное кол-во младенцев"])},
      "max_adults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальное кол-во гостей"])},
      "special_weekday_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спец цена за ночь в выбранные дни недели"])},
      "management_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбор за управление"])},
      "good_for_infants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подходит для младенцев (до 2 лет)"])},
      "user_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользовательская ссылки"])},
      "month_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидка за месяц"])},
      "week_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидка за неделю"])},
      "guests_rules_require_at_least_one_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требование одного или более завершенных бронирований на сервисе Трэвелуслуги"])},
      "guests_rules_require_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требование обязательного фото профиля"])},
      "booking_cancellation_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила отмены бронирования"])},
      "events_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешены ли мероприятия"])},
      "arrival_time_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время прибытия"])},
      "arrival_time_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время прибытия"])},
      "departure_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время выезда"])},
      "communications_with_guest_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Способ общения с гостями"])},
      "hours_to_confirm_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кол-во часов для завершения бронирования"])},
      "special_night_price_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гибкое спец. предложение"])},
      "rejection_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина отказа в одобрении"])},
      "good_for_kids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подходит для детей (2–12 лет)"])}
    }
  },
  "whatToHostOptions": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жилье целиком"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдельная комната"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место в общей комнате"])}
  }
}