import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class StateModule extends VuexModule {
  firstInitCompleted = false;

  get isFirstInitCompleted() {
    return this.firstInitCompleted;
  }

  @Mutation
  setFirstInitCompleted(payload) {
    this.firstInitCompleted = payload;
  }
}
