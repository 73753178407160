import { IUser, IUserDocument, IUserPublicData } from "@/interfaces/IUser";
import axios, { AxiosRequestConfig } from "axios";
import { IApiResponse } from "@/interfaces/IApiResponse";
import { IChange } from "@/interfaces/common";
import qs from "qs";

export type ISavedProfileResponse = IApiResponse<IUser>

export const getUserPublicData = async (userId: string): Promise<IUserPublicData> => {
  const response = await axios.get(`/users/${userId}/public_data`);
  return response.data.data;
};

interface ISingleUserIdResponse {
  user_id: string;
}

export const checkIfPublicProfileAvailable = async (publicProfileLink: string): Promise<ISingleUserIdResponse> => {
  const response = await axios.get(`/users/check_public_profile_link?public_profile_link=${publicProfileLink}`);
  return response.data.data;
};

export const usersSendAuthCode = async (payload: { [k: string]: any }): Promise<any> => {
  const getParams = qs.stringify(payload);
  const response = await axios.get(`/users/send_auth_code?${getParams}`);
  return response.data.data;
};

export const usersFindBy = async (payload: { [k: string]: any }): Promise<any> => {
  const getParams = qs.stringify(payload);
  const response = await axios.get(`/users/find_by?${getParams}`);
  return response.data.data;
};

export const usersCheckCode = async (userParams: { [p: string]: any }): Promise<any> => {
  const response = await axios.post(`/users/check_auth_code`, userParams);
  return response.data.data;
};

export const saveProfile = async (profile: Partial<IUser>, config: AxiosRequestConfig = {}): Promise<IUser> => {
  const response = await axios.patch(`/users/${profile.id}`, { user: profile }, config);
  return response.data.data;
};

export const updateAvatar = async (userId: string, avatar: File): Promise<string> => {
  const formData = new FormData();
  formData.append("avatar", avatar);

  const response = await axios.post(`/users/${userId}/avatar`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  return response.data;
};

export const usersReferralsIndex = async (userId: string) => {
  const response = await axios.get(`/users/${userId}/referrals`);
  return response.data.data;
}

export const usersSendEmailConfirmationMessage = async (userId: string): Promise<IUser> => {
  const response = await axios.post(`/users/${userId}/send_email_confirmation_message`);
  return response.data.data;
};

export const usersSendTextMessageWithConfirmation = async (userId: string, phone = ""): Promise<IUser> => {
  const response = await axios.post(`/users/${userId}/send_phone_confirmation_text_message?phone=${phone}`);
  return response.data.data;
};

export const usersCheckPhoneConfirmationCode = async (userId: string, code: string, phone = ""): Promise<IUser> => {
  const response = await axios.post(`/users/${userId}/check_phone_confirmation_code?code=${code}&phone=${phone}`, {}, { errorHandled: true } as AxiosRequestConfig);
  return response.data.data;
};

export const usersCheckEmailConfirmationCode = async (userId: string, code: string): Promise<IUser> => {
  const response = await axios.post(`/users/${userId}/check_email_confirmation_code?code=${code}`, {}, { errorHandled: true } as AxiosRequestConfig);
  return response.data.data;
};

export async function setUsersAddress(userId: string, address: any): Promise<IUser> {
  const response = await axios.patch(`/users/${userId}/set_address`, { address });
  return response.data.data;
}

export const getUserDocuments = async (userId: string): Promise<IUserDocument[]> => {
  const response = await axios.get(`/users/${userId}/documents`);
  return response.data.data;
};

export async function removeUserDocument(userId, documentId): Promise<void> {
  await axios.delete(`/users/${userId}/documents/${documentId}`);
}

export const removeAvatar = async (userId: string): Promise<void> => {
  await axios.delete(`/users/${userId}/avatar`);
};

export async function userProfileUpdate(userId: string, profile: unknown, config): Promise<IUser> {
  config = config || {};

  const response = await axios.patch(`/users/${userId}`, { user: profile }, config);
  return response.data.data;
}

export async function userChangesIndex(userId: string, pageNum): Promise<IPaginatedResponse<IChange[]>> {
  const response = await axios.get(`/users/${userId}/changes?page=${pageNum}`);
  return response.data.data;
}