export const apiHost: string = process.env.NODE_ENV === "production" ? "https://api.traveluslugi.ru" : "http://localhost:3000";
export const wsHost: string = process.env.NODE_ENV === "production" ? "wss://api.traveluslugi.ru" : "ws://localhost:3000";
export const baseURL = `${apiHost}`;
export const firebaseConfig = {
  apiKey: "AIzaSyCveIwpQqmWpJLDDSZhmvv7U3jWMcaVPms",
  authDomain: "traveluslugi.firebaseapp.com",
  projectId: "traveluslugi",
  storageBucket: "traveluslugi.appspot.com",
  messagingSenderId: "154053698838",
  appId: "1:154053698838:web:23e690997ba0535484dc37",
};
export const firebaseVapidKey = "BBHrNlHRG8BujlXEViJv3tj8WGFG_RrkhSfuS5h8cJZ4AQwDEQz-kiWpxFYg2kWkl3ZKlMsRcNtwJCOBiV1YxL0";

export const WhatToHostOptions = [
  {
    id: 1,
    name: "Жилье целиком",
    description: "В распоряжении гостей жилье целиком. Под этим обычно подразумевается спальня, ванная и кухня.",
  },
  {
    id: 2,
    name: "Отдельную комнату",
    description: "У гостей отдельная спальня, остальными помещениями они пользуются вместе с другими людьми.",
  },
  {
    id: 3,
    name: "Место в общей комнате",
    description: "Гости спят в спальне или общей зоне, которой могут пользоваться другие люди.",
  },
];

export const allowedDocumentMimeTypes = "image/png,image/jpeg,.doc,.docx,application/pdf"
export const allowedDocumentExtensions = "jpeg|jpe|jpg|gif|png|webp|pdf|doc|docx"
export const MAX_DOCUMENT_SIZE = 15 * 1024 * 1024;

export const allowedVideoMimeTypes = "video/mp4,video/quicktime,video/x-msvideo,video/x-ms-wmv"
export const allowedVideoExtensions = "mp4|mov|avi|wmv|png|webp|pdf|doc|docx"
export const MAX_VIDEO_SIZE = 100 * 1024 * 1024;


export const TRAVELUSLUGI_ALTERNATIVE_PLACE_NAME = "id-alternative";