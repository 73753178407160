export const hostRoutes = [{
  path: "/host",
  name: "host-root",
  redirect: { name: "host-today" },
  component: () => import("@/views/Host/HostLayout.vue"),
  children: [
    {
      path: "today",
      name: "host-today",
      component: () => import("@/views/Host/TodayPage.vue"),
    },
    {
      path: "booking-records",
      name: "host-booking-records-index",
      component: () => import("@/views/Host/BookingRecords/BookingRecordsIndex.vue"),
    },
    {
      path: "booking-records/:booking_record_id/details",
      name: "host-booking-records-details",
      component: () => import("@/views/Host/BookingRecords/BookingRecordsShowDetails.vue"),
    },
    {
      path: "incoming",
      name: "host-incoming",
      redirect: { name: "host-incoming-guests" },
      component: () => import("@/views/Host/IncomingMessages/IncomingMessages.vue"),
      children: [
        {
          path: "guests",
          name: "host-incoming-guests",
          component: () => import("@/views/Host/IncomingMessages/IncomingMessagesGuests.vue"),
        },
        {
          path: "support",
          name: "host-incoming-support",
          component: () => import("@/views/Host/IncomingMessages/IncomingMessagesSupport.vue"),
        },
      ],
    },
    {
      path: "message-templates",
      name: "host-message-templates",
      component: () => import("@/views/Host/MessagesTemplates/MessagesTemplatesIndex.vue"),
    },
    {
      path: "become-a-host",
      name: "host-become-a-host",
      component: () => import("@/views/Host/CreateOrRetrieveHost.vue"),
    },
    {
      path: "become-a-host/:dwelling_id",
      name: "host-become-a-host-dwelling",
      component: () => import("@/views/Host/BecomeHost.vue"),
    },
    {
      path: "calendar",
      name: "host-calendar",
      // component: () => import("@/views/Host/Calendar/CalendarIndex.vue"),
      // component: () => import("@/views/Host/Calendar/CalendarTUI.vue"),
      component: () => import("@/views/Host/Calendar/CalendarHorizontal.vue"),
    },
    {
      path: "calendar/:dwelling_id",
      name: "host-calendar-for-dwelling",
      component: () => import("@/views/Host/Calendar/CalendarIndex.vue"),
      // component: () => import("@/views/Host/Calendar/CalendarTUI.vue"),
      // component: () => import("@/views/Host/Calendar/CalendarHorizontal.vue"),
    },
    {
      path: "dwellings/create",
      name: "host-dwellings-create",
      component: () => import("@/views/Host/Dwellings/DwellingsCreate.vue"),
    },
    {
      path: "dwellings",
      name: "host-dwellings-index",
      component: () => import("@/views/Host/Dwellings/DwellingsIndex.vue"),
    },
    {
      path: "dwellings/:dwelling_id",
      name: "host-dwellings-edit",
      redirect: { name: "host-dwellings-edit-general" },
      component: () => import("@/views/Host/Dwellings/DwellingsEdit.vue"),
      children: [
        {
          path: "general",
          name: "host-dwellings-edit-general",
          component: () => import("@/views/Host/Dwellings/DwellingsEdit/DwellingsEditGeneral.vue"),
        },
        {
          path: "amenities",
          name: "host-dwellings-edit-amenities",
          component: () => import("@/views/Host/Dwellings/DwellingsEdit/DwellingsEditAmenities.vue"),
        },
        {
          path: "photos",
          name: "host-dwellings-edit-photos",
          component: () => import("@/views/Host/Dwellings/DwellingsEdit/DwellingsEditPhotos.vue"),
        },
        {
          path: "pricing-and-dates",
          name: "host-dwellings-edit-pricing-and-dates",
          component: () => import("@/views/Host/Dwellings/DwellingsEdit/DwellingsEditPricing.vue"),
        },
        {
          path: "rules-and-conditions",
          name: "host-dwellings-edit-rules-and-conditions",
          component: () => import("@/views/Host/Dwellings/DwellingsEdit/DwellingsEditRulesAndConditions.vue"),
        },
        {
          path: "for-guests",
          name: "host-dwellings-edit-for-guests",
          component: () => import("@/views/Host/Dwellings/DwellingsEdit/DwellingsEditForGuests.vue"),
        },
        {
          path: "changes",
          name: "host-dwellings-changes",
          component: () => import("@/views/Host/Dwellings/DwellingsEdit/DwellingsEditChanges.vue"),
        },
        {
          path: "documents",
          name: "host-dwellings-edit-documents",
          component: () => import("@/views/Host/Dwellings/DwellingsEdit/DwellingsEditDocuments.vue"),
        },
      ],
    },
    {
      path: "dwellings/:dwelling_id/reviews",
      name: "host-dwellings-reviews",
      component: () => import("@/views/Host/ReviewsFromUsers/ReviewFromUser.vue"),
    },
    {
      path: "dwellings/:dwelling_id/reviews/:review_id",
      name: "host-dwellings-reviews-review",
      component: () => import("@/views/Host/ReviewsFromUsers/ReviewFromUser.vue"),
    },
  ],
}];
