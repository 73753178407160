import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import FirebaseService from "@/core/services/FirebaseService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";
import "bootstrap";
import ru from "yup-locale-ru";
import { setLocale } from "yup";
import VCalendar from "v-calendar";
import "v-calendar/dist/style.css";
import WebsocketClientService from "@/core/services/WebsocketClientService";
import Toast, { PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";
import moment from "moment-timezone";
import { VueMasonryPlugin } from "vue-masonry";
import { NotificationsService } from "@/core/services/NotificationsService";
import Maska from "maska";
import { createHead } from "@vueuse/head";
import mitt from "mitt";

moment.locale("ru");
setLocale(ru);

const emitter = mitt();

const app = createApp(App);
const head = createHead()

const options: PluginOptions = {
  // You can set your default options here
};

app.use(Toast, options);
app.use(VCalendar);
app.use(store);
app.use(Maska);
app.use(router);
app.use(ElementPlus);
app.use(VueMasonryPlugin);

ApiService.init(app);
ApiService.setInterceptors();

WebsocketClientService.init(app);
NotificationsService.init(store);
FirebaseService.init(app);

initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);
app.use(head);

app.provide('emitter', emitter)

app.mount("#app");
