import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { getDwellingIdByUserLink } from "@/composables/repositories/DwellingsRepository";
import { checkIfPublicProfileAvailable } from "@/composables/repositories/UsersRepository";
import { hostRoutes } from "@/router/hostRoutes";
import { guestRoutes } from "@/router/guestRoutes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/rent",
    name: "root-root",
    component: () => import("@/layout/Layout.vue"),
    children: [
      ...guestRoutes,
      ...hostRoutes,
    ],
  },
  {
    path: "/oauthCallback",
    component: () => import("@/views/OauthCallbacks/OauthCallback.vue"),
    meta: { allowUnauthorized: true },
  },
  {
    path: "/users/:user_id/email-confirmation",
    name: "user-email-confirmation",
    redirect: "",
    component: () => import("@/views/User/EmailConfirmation.vue"),
    meta: { allowUnauthorized: true },
  },
  {
    path: "/bookings/:booking_id/print",
    name: "booking-page-print",
    component: () => import("@/views/User/BookingRecords/BookingRecordPrint.vue"),
  },
  {
    path: "/h/:dwelling_user_link",
    name: "dwelling-user-link",
    redirect: "",
    meta: { allowUnauthorized: true },
    beforeEnter: async (to, from, next) => {
      const response = await getDwellingIdByUserLink(to.params.dwelling_user_link as string);
      const dwellingId = response.dwelling_id;

      if (dwellingId) {
        next({ name: "dwelling-page", params: { dwelling_id: dwellingId } });
      } else {
        next({ name: "root-root" });
      }
    },
  },
  {
    path: "/p/:public_profile_link",
    name: "user-profile-public-link",
    redirect: "",
    meta: { allowUnauthorized: true },
    beforeEnter: async (to, from, next) => {
      const response = await checkIfPublicProfileAvailable(to.params.public_profile_link as string);
      const userId = response.user_id;
      if (userId) {
        next({ name: "user-public-profile", params: { user_id: userId } });
      } else {
        next({ name: "root-root" });
      }
    },
  },
  {
    path: "/coming-soon",
    name: "coming-soon",
    component: () => import("@/views/ComingSoon.vue"),
  },
  {
    path: "/new-host",
    name: "new-host",
    component: () => import("@/components/page-layouts/NewHostLayout.vue"),
    meta: { allowUnauthorized: true },
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: { allowUnauthorized: true },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: { allowUnauthorized: true },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: { allowUnauthorized: true },
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (routeTo, routeFrom) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  const authorized = await store.dispatch(Actions.VERIFY_AUTH);

  if (routeTo.name == "sign-up") {
    const query = {};
    if (!authorized) {
      query["show_sign_in"] = "1";
      if (routeTo.query.ref_id) {
        query["ref_id"] = routeTo.query.ref_id;
      }
    }
    await router.push({
      name: "root-root",
      query: query,
    });
  }

  if (!authorized && !routeTo.meta.allowUnauthorized) {
    // console.log({ routeTo });
    if (store.getters.isFirstInitCompleted) {
      store.commit("setShowAuthModal", routeTo.fullPath);
    } else {
      await router.push({
        name: "root-root",
        query: {
          return_url: routeTo.fullPath,
          show_sign_in: "1",
        },
      });
    }
    return false;
    // if (!routeTo.meta.allowUnauthorized) {
    //   await router.push({ name: "sign-in", query: { return_url: encodeURIComponent(routeTo.fullPath) } });
    //   return false;
    // }
  }

  // Scroll page to top on every route change

  if (!routeTo.hash && !routeTo.params.noScroll) {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
  return true;
});

export default router;
