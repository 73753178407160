import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { FirebaseApp } from "@firebase/app";
import { App } from "vue";
import { saveToken } from "@/composables/repositories/TokensRepository";
import { firebaseConfig, firebaseVapidKey } from "@/core/data/constants";
import { useToast } from "vue-toastification";

const toast = useToast();

export default class FirebaseService {
  public static firebaseApp: FirebaseApp;
  public static vueInstance: App | undefined;
  public static messagingToken: string;

  public static init(app: App<Element> | undefined) {
    FirebaseService.firebaseApp = initializeApp(firebaseConfig);
    FirebaseService.vueInstance = app;
  }

  public static async initializeMessaging() {
    const messaging = getMessaging(FirebaseService.firebaseApp);
    try {
      const token = await getToken(messaging, { vapidKey: firebaseVapidKey });
      if (token) {
        FirebaseService.messagingToken = token;
        await saveToken(token);
      } else {
        console.log("No registration token available. Request permission to generate one.");
        return;
      }
    } catch (e) {
      console.log("Wooooops!", e);
      return;
    }

    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      if (payload.notification != null && payload.notification.body != null) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { title, body } = payload.notification;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const notificationOptions = {
          body: body ?? "",
          icon: "/favicon.ico",
        };

        const toastType = payload.data?.notificationType ?? "info";

        toast[toastType](body);
      }
    });
  }
}