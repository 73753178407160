export const guestRoutes = [
  {
    path: "/rent",
    name: "rent",
    component: () => import("@/views/User/RentADwelling.vue"),
    meta: { allowUnauthorized: true },
  },
  {
    path: "/results",
    name: "search-results-type-b",
    component: () => import("@/views/User/SearchTypeB/SearchResults.vue"),
    meta: { allowUnauthorized: true },
  },
  {
    path: "/wishlists/",
    name: "wishlists-index",
    component: () => import("@/views/User/Wishlists/WishlistsIndex.vue"),
  },
  {
    path: "/wishlists/:wishlist_id",
    name: "wishlist-page",
    component: () => import("@/views/User/Wishlists/WishlistPage.vue"),
  },
  {
    path: "/conversations/",
    name: "conversations-index",
    component: () => import("@/views/User/Conversations/ConversationsIndex.vue"),
  },
  {
    path: "/conversations/:conversation_id",
    name: "conversations-conversation-page",
    component: () => import("@/views/User/Conversations/ConversationsConversationPage.vue"),
  },
  {
    path: "/dwellings/:dwelling_id",
    name: "dwelling-page",
    component: () => import("@/views/User/Dwellings/DwellingsPage.vue"),
    meta: { allowUnauthorized: true },
  },
  {
    path: "/dwellings/:dwelling_id/contact-host",
    name: "dwelling-page-contact-host",
    component: () => import("@/views/User/Dwellings/DwellingsPageContactHost.vue"),
    meta: { allowUnauthorized: false },
  },
  {
    path: "/dwellings/:dwelling_id/booking-confirmation",
    name: "dwelling-booking-confirmation",
    component: () => import("@/views/User/Dwellings/DwellingsBookingConfirmation.vue"),
    meta: { allowUnauthorized: true },
  },
  {
    path: "/bookings",
    name: "bookings-index",
    redirect: { name: "booking-records-all" },
    props: { show: "undefined" },
    component: () => import("@/views/User/BookingRecords/BookingRecordsIndexBase.vue"),
    children: [
      {
        path: "all",
        name: "booking-records-all",
        props: { show: "all" },
        component: () => import("@/views/User/BookingRecords/BookingRecordsIndex.vue"),
      },
      {
        path: "ongoing",
        name: "booking-records-ongoing",
        props: { show: "ongoing" },
        component: () => import("@/views/User/BookingRecords/BookingRecordsIndex.vue"),
      },
      {
        path: "future",
        name: "booking-records-future",
        props: { show: "future" },
        component: () => import("@/views/User/BookingRecords/BookingRecordsIndex.vue"),
      },
      {
        path: "past",
        name: "booking-records-past",
        props: { show: "past" },
        component: () => import("@/views/User/BookingRecords/BookingRecordsIndex.vue"),
      },
      {
        path: "cancelled",
        name: "booking-records-cancelled",
        props: { show: "cancelled" },
        component: () => import("@/views/User/BookingRecords/BookingRecordsIndex.vue"),
      },
    ],
  },
  {
    path: "/bookings/:booking_id",
    name: "booking-page",
    component: () => import("@/views/User/BookingRecords/BookingRecordPage.vue"),
    meta: { allowUnauthorized: true },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/User/Settings/SettingsIndex.vue"),
  },
  {
    path: "/settings/personal",
    name: "settings-personal",
    component: () => import("@/views/User/Settings/SettingsPages/SettingsPersonal.vue"),
  },
  {
    path: "/settings/security",
    name: "settings-security",
    redirect: { name: "settings-security-sign-in" },
    component: () => import("@/views/User/Settings/SettingsPages/SettingsSecurity.vue"),
    children: [
      {
        path: "sign-in",
        name: "settings-security-sign-in",
        component: () => import("@/views/User/Settings/SettingsPages/SettingsSecurityParts/SettingsSecuritySignIn.vue"),
      },
      {
        path: "changes",
        name: "settings-security-changes",
        component: () => import("@/views/User/Settings/SettingsPages/SettingsSecurityParts/SettingsSecurityChanges.vue"),
      },
      {
        path: "active-sessions",
        name: "settings-security-active-sessions",
        component: () => import("@/views/User/Settings/SettingsPages/SettingsSecurityParts/SettingsSecurityActiveSessions.vue"),
      },
    ],
  },
  {
    path: "/settings/notifications",
    name: "settings-notifications",
    component: () => import("@/views/User/Settings/SettingsPages/SettingsNotifications.vue"),
  },
  {
    path: "/settings/finances",
    name: "settings-finances",
    redirect: { name: "settings-finances-payments" },
    component: () => import("@/views/User/Settings/SettingsPages/SettingsFinances.vue"),
    children: [
      {
        path: "payments",
        name: "settings-finances-payments",
        component: () => import("@/views/User/Settings/SettingsPages/SettingsFinancesParts/SettingsFinancesPayments.vue"),
      },
      {
        path: "payouts",
        name: "settings-finances-payouts",
        component: () => import("@/views/User/Settings/SettingsPages/SettingsFinancesParts/SettingsFinancesPayouts.vue"),
      },
      {
        path: "taxes",
        name: "settings-finances-taxes",
        component: () => import("@/views/User/Settings/SettingsPages/SettingsFinancesParts/SettingsFinancesTaxes.vue"),
      },
      {
        path: "fees",
        name: "settings-finances-fees",
        component: () => import("@/views/User/Settings/SettingsPages/SettingsFinancesParts/SettingsFinancesFees.vue"),
      },
      {
        path: "gifts",
        name: "settings-finances-gifts",
        component: () => import("@/views/User/Settings/SettingsPages/SettingsFinancesParts/SettingsFinancesGifts.vue"),
      },
    ],
  },
  {
    path: "/settings/identity-confirmation",
    name: "settings-identity-confirmation",
    component: () => import("@/views/User/Settings/SettingsPages/SettingsIdentityConfirmation.vue"),
  },
  {
    path: "/settings/referrals-and-coupons",
    name: "settings-referrals-and-coupons",
    redirect: { name: "settings-referrals-and-coupons-referrals" },
    component: () => import("@/views/User/Settings/SettingsPages/SettingsReferralsAndCoupons.vue"),
    children: [
      {
        path: "referrals",
        name: "settings-referrals-and-coupons-referrals",
        component: () => import("@/views/User/Settings/SettingsPages/SettingsReferralsAndCouponsParts/SettingsReferrals.vue"),
      },
      {
        path: "coupons",
        name: "settings-referrals-and-coupons-coupons",
        component: () => import("@/views/User/Settings/SettingsPages/SettingsReferralsAndCouponsParts/SettingsCoupons.vue"),
      },
    ]
  },
  {
    path: "/host/bookings/:booking_record_id/reviews/user/:user_id/write",
    name: "write-a-review-to-user",
    component: () => import("@/views/Host/WriteReviewFromHost.vue"),
  },
  {
    path: "/bookings/:booking_record_id/reviews/host/:host_id/write",
    name: "write-a-review-to-host",
    component: () => import("@/views/User/WriteReviewFromUser.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/User/ProfilePage.vue"),
  },
  {
    path: "/users/:user_id",
    name: "user-public-profile",
    component: () => import("@/views/User/PublicProfile.vue"),
    meta: { allowUnauthorized: true },
  },
  {
    path: "support",
    name: "support-index",
    component: () => import("@/views/User/Support/SupportTicketsIndex.vue"),
  },
  {
    path: "support/new",
    name: "support-new",
    component: () => import("@/views/User/Support/SupportTicketsNew.vue"),
  },
  {
    path: "support/:support_ticket_id",
    name: "support-ticket-page",
    component: () => import("@/views/User/Support/SupportTicketsTicketPage.vue"),
  },
];