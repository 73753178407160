import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { INotification } from "@/interfaces/INotification";

@Module
export default class NotificationsModule extends VuexModule {
  notifications: INotification[] = [];

  @Mutation
  setNotifications(notifications) {
    this.notifications = notifications;
  }

  @Mutation
  addNotification(notification: INotification) {
    this.notifications.unshift(notification);
  }

  @Mutation
  addNotifications(notifications: INotification[]) {
    this.notifications = [...notifications, ...this.notifications]
  }

  @Mutation
  readAllNotifications() {
    this.notifications.forEach(n => {n.seen = true;});
  }

  @Mutation
  hideReadNotifications() {
    this.notifications = this.notifications.filter(n => !n.seen);
  }

  @Mutation
  updateNotification(notification: INotification) {
    const existingNotificationIndex = this.notifications.findIndex(n => n.id === notification.id);
    if (existingNotificationIndex === -1) return;

    this.notifications[existingNotificationIndex] = notification;
  }
}
